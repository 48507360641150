import {animateInViewport} from "./transition";

let jQueryBridget = require( 'jquery-bridget' );
let Masonry = require( 'masonry-layout' );

jQueryBridget( 'masonry', Masonry, $ );

export const windowSize = {
	width: $( window ).width(),
};

$( function() {
	$( 'html,body' ).scrollTop( 0 );

	window.msnry = $( '.masonry' ).on( 'layoutComplete', function() {
		animateInViewport();
	} ).masonry( {
		itemSelector: '.masonry-item',
		gutter: 24,
		columnWidth: '.masonry-item:not(.hidden):not(.filtered)',
		transitionDuration: 0,
		horizontalOrder: true,
	} );
} );

$( window ).on( 'resize', function() {
	windowSize.width = $( this ).width();
} );
