export function esc_title( string ) {
	string = string.toLowerCase().replace( /[áàâå]/g, 'a' )
		.replace( /[ø]/g, 'o' )
		.replace( /[úùû]/g, 'u' )
		.replace( /[äæ]/g, 'ae' )
		.replace( /[ö]/g, 'oe' )
		.replace( /[ü]/g, 'ue' )
		.replace( /[ß]/g, 'ss' )
		.replace( /[éèë]/g, 'e' )
		.replace( /[í]/g, 'i' );
	return string.replace( /[^0-9a-z]/gi, '' );
}
