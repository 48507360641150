import Flickity from 'flickity';
import 'flickity-bg-lazyload';
import 'flickity-imagesloaded';
import jQueryBridget from 'jquery-bridget';

import {animateInViewport} from '../transition';

// make Flickity a jQuery plugin
Flickity.setJQuery( $ );
jQueryBridget( 'flickity', Flickity, $ );

$( function() {
	$( '.tenants-carousel' ).each( function() {
		const self = this;
		const $this = $( this );
		const flkty = {
			init() {
				this.flkty = new Flickity( self, {
					prevNextButtons: false,
					pageDots: false,
					wrapAround: true,
					freeScroll: true,
					imagesLoaded: true,
				} );
				this.flkty.x = 0;
				this.autoplaySpeed = 70;
				this.loop();
			},

			loop() {
				const now = Date.now();
				const elapsedMillis = now - (this.autoplayLastTimestamp || now);
				this.autoplayLastTimestamp = now;
				this.flkty.x += (this.autoplaySpeed || 50) * elapsedMillis / 1000 * (this.autoplayDirection || -1);
				this.flkty.integratePhysics();
				this.flkty.settle( this.flkty.x );
				animateInViewport( $this );
				window.requestAnimationFrame( this.loop.bind( this ) );
			},
		};

		flkty.init();
	} );

	$( '.activity-carousel' ).each( function( index ) {
		const self = this;
		const $this = $( this );
		const flkty = {
			init() {
				this.flkty = new Flickity( self, {
					prevNextButtons: false,
					pageDots: false,
					wrapAround: true,
					freeScroll: true,
					imagesLoaded: true,
				} );
				this.flkty.x = 0;
				this.autoplaySpeed = 50;
				this.autoplayDirection = (index % 2 === 0) ? 1 : -1;
				this.loop();
			},

			loop() {
				const now = Date.now();
				const elapsedMillis = now - (this.autoplayLastTimestamp || now);
				this.autoplayLastTimestamp = now;
				this.flkty.x += (this.autoplaySpeed || 50) * elapsedMillis / 1000 * (this.autoplayDirection || -1);
				this.flkty.integratePhysics();
				this.flkty.settle( this.flkty.x );
				animateInViewport( $this );
				window.requestAnimationFrame( this.loop.bind( this ) );
			},
		};

		flkty.init();
	} );

	$( '.card-carousel.flickity-carousel' ).each( function( index ) {
		const self = this;
		const $this = $( this );
		const flkty = {
			init() {
				this.flkty = new Flickity( self, {
					prevNextButtons: false,
					pageDots: false,
					wrapAround: true,
					freeScroll: true,
					imagesLoaded: true,
					bgLazyLoad: true,
					cellAlign: 'left',
				} );
				this.flkty.x = 0;
				this.autoplaySpeed = 50;
				this.loop();
				initializeFollowMe( $this.parent().find( '.follow-me' ), $this );
			},

			loop() {
				const now = Date.now();
				const elapsedMillis = now - (this.autoplayLastTimestamp || now);
				this.autoplayLastTimestamp = now;
				this.flkty.x += (this.autoplaySpeed || 50) * elapsedMillis / 1000 * (this.autoplayDirection || -1);
				this.flkty.integratePhysics();
				this.flkty.settle( this.flkty.x );
				animateInViewport( $this );
				window.requestAnimationFrame( this.loop.bind( this ) );
			},
		};

		flkty.init();
	} );
} );

$( '.image-carousel' ).each( function() {
	const $this = $( this );

	let pagination = false;
	if ( $this.hasClass( 'show-bullets' ) ) {
		pagination = {
			el: '.swiper-pagination',
		};
	}

	let swiper = new Swiper( this, {
		pagination: pagination,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 0,
		loop: true,
		on: {
			afterInit: function( swiper ) {
				$this.find( '.swiper-pagination' ).attr( 'data-dot-style', $this.find( '.swiper-slide' ).eq( 0 ).attr( 'data-dot-style' ) );
			},
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();

		const currentSlide = swiper.slides[ swiper.activeIndex ];
		const imageUrl = currentSlide.getAttribute( 'data-image-url' );
		$this.find( '.zoom-button' ).attr( 'href', imageUrl );
		$this.find( '.swiper-pagination' ).attr( 'data-dot-style', currentSlide.getAttribute( 'data-dot-style' ) );
	} );
} );

$( '.post-carousel' ).each( function() {
	let $this = $( this );

	let swiper = new Swiper( this, {
		pagination: false,
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
		grabCursor: true,
		autoHeight: false,
		slidesPerView: 1.15,
		spaceBetween: 10,
		breakpoints: {
			1024: {
				slidesPerView: 2.75,
				spaceBetween: 64,
			},
		},
		loop: false,
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );
} );

$( '.card-carousel:not(.flickity-carousel)' ).each( function() {
	let $this = $( this );

	let swiper = new Swiper( this, {
		pagination: false,
		grabCursor: false,
		autoHeight: true,
		slidesPerView: 1.25,
		spaceBetween: 20,
		breakpoints: {
			1024: {
				slidesPerView: 3.65,
				spaceBetween: 64,
			},
		},
		loop: true,
		speed: 20000, // TODO: all autoplay movements
		autoplay: {
			delay: 0,
			disableOnInteraction: true,
			// reverseDirection: true,
		},
		on: {
			afterInit: function( swiper ) {
				initializeFollowMe( $this.find( '.follow-me' ), $this );
			},
		},
	} );

	swiper.on( 'touchEnd', function() {
		swiper.autoplay.start();
	} );

	swiper.on( 'click touchStart tab', function() {
		$this.find( '.swiper-wrapper' ).removeClass( 'ease-linear' );
		swiper.autoplay.stop();
	} );
} );

$( '.timeline-carousel' ).each( function() {
	let $this = $( this );

	function handleTimelineBullets() {
		const activeIndex = this.snapIndex;
		const slidesInView = ($( window ).width() >= 1024) ? 3 : 1;
		const $container = $this.parents( '.carousel-container' );

		console.log( activeIndex );

		$container.find( '.nav-bullet-dot' ).each( function( index, bullet ) {
			if ( index >= activeIndex && index < activeIndex + slidesInView ) {
				$( bullet ).addClass( 'active' );
			} else {
				$( bullet ).removeClass( 'active' );
			}
		} );

		$container.find( '.nav-bullet-year' ).removeClass( 'active' );
		$container.find( '.nav-bullet-dot.active' ).prev( '.nav-bullet-year' ).addClass( 'active' );
	}

	let swiper = new Swiper( this, {
		pagination: false,
		grabCursor: true,
		autoHeight: true,
		slidesPerView: 1.15,
		spaceBetween: 10,
		breakpoints: {
			1024: {
				slidesPerView: 3.65,
				spaceBetween: 64,
			},
		},
		loop: false,
		on: {
			init: handleTimelineBullets,
			transitionEnd: handleTimelineBullets,
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();
	} );
} );

$( '.logo-carousel' ).each( function() {
	let swiper = new Swiper( this, {
		slidesPerView: 'auto',
		direction: 'horizontal',
		spaceBetween: 0,
		freeMode: true,
		freeModeMomentum: false,
		freeModeMomentumBounce: false,
		speed: 5000,
		autoplay: {
			delay: 0,
			disableOnInteraction: true,
			//waitForTransition: false,
		},
		loop: true,
		grabCursor: true,
	} );

	swiper.on( 'touchEnd', function() {
		swiper.autoplay.start();
	} );

	swiper.on( 'click touchStart tab', function() {
		$this.find( '.swiper-wrapper' ).removeClass( 'ease-linear' );
	} );
} );

$( '.property-image-carousel' ).each( function() {
	let $this = $( this );

	let swiper = new Swiper( this, {
		grabCursor: true,
		slidesPerView: 1.4,
		spaceBetween: 8,
		breakpoints: {
			1024: {
				spaceBetween: 16,
			},
		},
		loop: true,
		pagination: {
			el: '.swiper-pagination',
		},
		on: {
			afterInit: function( swiper ) {
				$this.find( '.swiper-pagination' ).attr( 'data-dot-style', $this.find( '.swiper-slide' ).eq( 0 ).attr( 'data-dot-style' ) );
			},
		},
		navigation: {
			nextEl: $this.parents( '.carousel-container' ).find( '.carousel-next' )[ 0 ],
			prevEl: $this.parents( '.carousel-container' ).find( '.carousel-previous' )[ 0 ],
		},
	} );

	swiper.on( 'slideChange slideChangeTransitionEnd', function() {
		animateInViewport();

		const currentSlide = swiper.slides[ swiper.activeIndex ];
		const imageUrl = currentSlide.getAttribute( 'data-image-url' );
		$this.find( '.zoom-button' ).attr( 'href', imageUrl );
		$this.find( '.swiper-pagination' ).attr( 'data-dot-style', currentSlide.getAttribute( 'data-dot-style' ) );
	} );
} );

$( '.floorplans-carousel' ).each( function() {
	let $this = $( this );

	let floorplansSwiper = new Swiper( this, {
		loop: true,
		pagination: false,
		effect: 'fade',
		fadeEffect: {
			crossFade: true,
		},
		autoHeight: true,
		breakpoints: {
			1024: {
				autoHeight: false,
			},
		},
		slidesPerView: 1,
		grabCursor: false,
		speed: 750,
		allowTouchMove: false,
		a11y: {
			enabled: false,
		},
	} );

	const $swiperNavContainer = $this.parents( '.carousel-container' ).find( '.swiper-nav-container' );

	$swiperNavContainer.each( function() {
		$( this ).find( '.swiper-nav' ).each( function( index ) {
			$( this ).on( 'click', function() {
				$swiperNavContainer.find( '.swiper-nav' ).removeClass( 'active' );
				floorplansSwiper.slideToLoop( index );
			} );
		} );
	} );

	$( '[data-slide-to]' ).on( 'click', function() {
		const slideTo = $( this ).data( 'slide-to' );
		floorplansSwiper.slideToLoop( slideTo );
	} );
} );

function initializeFollowMe( $followMe, $container ) {
	if ( !$container && !$container.length ) {
		$container = $followMe.parent();
	}

	let active = false;

	$container.on( 'mouseenter', function( e ) {
		active = true;
		$followMe.addClass( '!opacity-100' );
	} ).on( 'mouseleave', function( e ) {
		active = false;
		$followMe.removeClass( '!opacity-100' );
	} ).on( 'mousemove pointermove', function( e ) {
		if ( active ) {
			const x = e.pageX;
			const y = e.pageY;
			const scrollLeft = (window.pageXOffset !== undefined) ? window.pageXOffset : (document.documentElement || document.body.parentNode || document.body).scrollLeft;
			const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

			$followMe.css( {
				left: (x - scrollLeft - $followMe.width() / 2) + 'px',
				top: (y - scrollTop - $followMe.height() / 2) + 'px',
			} );

			animateInViewport();
		}
	} );
}
