function scrollText() {
	document.querySelectorAll( ".scrollable-text-line" ).forEach( function( element ) {
		const viewportHeight = window.innerHeight;
		const rect = element.getBoundingClientRect();
		const position = 1 - (rect.top + rect.height / 2) / (viewportHeight + rect.height - rect.height);
		const multiplier = 30;
		const translateX = ((element.classList.contains( 'to-right' )) ? 0 : -50) + position * multiplier * ((element.classList.contains( 'to-right' )) ? -1 : 1);
		element.style.transform = `translate(${translateX}%, 0)`;
	} );
	window.requestAnimationFrame( scrollText );
}

window.requestAnimationFrame( scrollText );