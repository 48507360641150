import 'is-in-viewport';

$( function() {
	animateInViewport();
} );

$( window ).scroll( function() {
	setTimeout( function() {
		animateInViewport();
	}, 100 );
} );

export function animateInViewport( $container = document ) {
	$( '.move-up-in-viewport, .move-down-in-viewport, .move-left-in-viewport, .move-right-in-viewport, .fade-in-in-viewport, .zoom-in-in-viewport, .active-in-viewport, .full-width-in-viewport', $container ).filter( ':not(.wait-to-animate)' ).each( function() {
		var $this = $( this );

		if ( $this.is( ':in-viewport' ) ) {
			$this.addClass( 'active' );
		}
	} );
}
