var mapsCenters = [];
var map;
var latLonBounds;
var googleMarkers = [];
var infoWindow = null;
var mapDefaults = {
	latitude: 59.963934,
	longitude: 10.781839,
	zoom: 17,
};
var manualPositioning = false;

function createGoogleMap( mapSettings, markers ) {
	if ( !mapSettings.element.length ) {
		return;
	}

	var latitude = mapDefaults.latitude;
	if ( mapSettings.latitude ) {
		latitude = mapSettings.latitude;
	}

	var longitude = mapDefaults.longitude;
	if ( mapSettings.latitude ) {
		longitude = mapSettings.longitude;
	}

	var zoom = mapDefaults.zoom;
	if ( mapSettings.zoom ) {
		zoom = mapSettings.zoom;
	}

	var scrollwheel = false;
	if ( mapSettings.scrollwheel ) {
		scrollwheel = mapSettings.scrollwheel;
	}

	var styles = [
		{
			"featureType": "administrative",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#054059",
				},
				{
					"saturation": "-100",
				},
			],
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
				{
					"color": "#f9f9f9",
				},
			],
		},
		{
			"featureType": "landscape.man_made",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off",
				},
			],
		},
		{
			"featureType": "landscape.natural.terrain",
			"elementType": "all",
			"stylers": [
				{
					"color": "#dbe3e8",
				},
				{
					"lightness": "-15",
				},
			],
		},
		{
			"featureType": "poi",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off",
				},
			],
		},
		{
			"featureType": "poi.business",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off",
				},
			],
		},
		{
			"featureType": "poi.park",
			"elementType": "all",
			"stylers": [
				{
					"lightness": "7",
				},
				{
					"saturation": "-100",
				},
			],
		},
		{
			"featureType": "poi.park",
			"elementType": "labels.text",
			"stylers": [
				{
					"visibility": "simplified",
				},
				{
					"saturation": "-100",
				},
				{
					"lightness": "-38",
				},
			],
		},
		{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on",
				},
				{
					"weight": "0.58",
				},
				{
					"lightness": "-20",
				},
			],
		},
		{
			"featureType": "road",
			"elementType": "labels",
			"stylers": [
				{
					"gamma": "0.25",
				},
				{
					"lightness": "34",
				},
				{
					"saturation": "17",
				},
				{
					"weight": "0.75",
				},
			],
		},
		{
			"featureType": "road",
			"elementType": "labels.text",
			"stylers": [
				{
					"saturation": "-100",
				},
			],
		},
		{
			"featureType": "road",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"invert_lightness": true,
				},
				{
					"saturation": "-100",
				},
			],
		},
		{
			"featureType": "road",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off",
				},
				{
					"weight": "0.97",
				},
				{
					"saturation": "-36",
				},
				{
					"lightness": "3",
				},
				{
					"invert_lightness": true,
				},
			],
		},
		{
			"featureType": "road.highway",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on",
				},
				{
					"weight": "0.50",
				},
			],
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry",
			"stylers": [
				{
					"weight": "0.25",
				},
				{
					"visibility": "on",
				},
				{
					"color": "#054059",
				},
			],
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"weight": "0.5",
				},
				{
					"visibility": "off",
				},
			],
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"weight": "0.25",
				},
				{
					"visibility": "off",
				},
			],
		},
		{
			"featureType": "road.highway",
			"elementType": "labels",
			"stylers": [
				{
					"lightness": "0",
				},
				{
					"visibility": "simplified",
				},
			],
		},
		{
			"featureType": "road.highway",
			"elementType": "labels.text",
			"stylers": [
				{
					"visibility": "on",
				},
			],
		},
		{
			"featureType": "road.highway",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off",
				},
			],
		},
		{
			"featureType": "road.highway.controlled_access",
			"elementType": "all",
			"stylers": [
				{
					"weight": "1.25",
				},
				{
					"visibility": "on",
				},
			],
		},
		{
			"featureType": "road.highway.controlled_access",
			"elementType": "labels.icon",
			"stylers": [
				{
					"lightness": "10",
				},
				{
					"weight": "1",
				},
				{
					"visibility": "on",
				},
			],
		},
		{
			"featureType": "road.arterial",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off",
				},
			],
		},
		{
			"featureType": "road.local",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "off",
				},
			],
		},
		{
			"featureType": "transit",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on",
				},
				{
					"color": "#054059",
				},
			],
		},
		{
			"featureType": "transit",
			"elementType": "labels.text",
			"stylers": [
				{
					"lightness": "-35",
				},
			],
		},
		{
			"featureType": "transit.line",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "simplified",
				},
				{
					"lightness": "1",
				},
				{
					"color": "#054059",
				},
				{
					"weight": "1",
				},
			],
		},
		{
			"featureType": "transit.line",
			"elementType": "geometry",
			"stylers": [
				{
					"lightness": "1",
				},
				{
					"weight": "1",
				},
			],
		},
		{
			"featureType": "transit.line",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "off",
				},
			],
		},
		{
			"featureType": "transit.station.bus",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "simplified",
				},
			],
		},
		{
			"featureType": "transit.station.rail",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "simplified",
				},
			],
		},
		{
			"featureType": "transit.station.rail",
			"elementType": "labels.icon",
			"stylers": [
				{
					"lightness": "1",
				},
				{
					"saturation": "16",
				},
			],
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
				{
					"color": "#cddadc",
				},
				{
					"visibility": "on",
				},
			],
		},
		{
			"featureType": "water",
			"elementType": "labels",
			"stylers": [
				{
					"saturation": "-100",
				},
				{
					"lightness": "-60",
				},
			],
		},
		{
			"featureType": "water",
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "simplified",
				},
			],
		},
	];

	var mapLocation = new google.maps.LatLng( latitude, longitude );

	var mapOptions = {
		zoom: zoom,
		center: mapLocation,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		draggable: true,
		scrollwheel: scrollwheel,
		gestureHandling: 'cooperative',
		mapTypeControl: false,
		mapTypeControlOptions: {
			style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
			position: google.maps.ControlPosition.TOP_CENTER,
		},
		zoomControl: true,
		zoomControlOptions: {
			position: google.maps.ControlPosition.RIGHT_TOP,
		},
		scaleControl: false,
		streetViewControl: false,
		streetViewControlOptions: {
			position: google.maps.ControlPosition.RIGHT_TOP,
		},
		fullscreenControl: false,
		mapId: mapSettings.id,
	};

	map = new google.maps.Map( mapSettings.element[ 0 ], mapOptions );

	if ( mapSettings.maxZoom ) {
		map.setOptions( { maxZoom: mapSettings.maxZoom } );
	}

	if ( mapSettings.satellite ) {
		map.setMapTypeId( 'satellite' );
	} else {
		var styledMap = new google.maps.StyledMapType( styles, { name: 'Styled Map' } );
		map.mapTypes.set( 'map_style', styledMap );
		map.setMapTypeId( 'map_style' );
	}

	latLonBounds = new google.maps.LatLngBounds();

	infoWindow = new google.maps.InfoWindow( {
		content: '...',
		maxWidth: 250,
	} );

	/*
	map.addListener( 'center_changed', function() {
	  resetMapOverlays();
	} );

	map.addListener( 'bounds_changed', function() {
	  resetMapOverlays();
	} );

	map.addListener( 'zoom_changed', function() {
	  resetMapOverlays();
	} );
	 */

	if ( markers ) {
		for ( var markerIndex = 0; markerIndex < markers.length; markerIndex++ ) {
			var markerPosition = new google.maps.LatLng( markers[ markerIndex ].latitude, markers[ markerIndex ].longitude );

			latLonBounds.extend( markerPosition );

			var markerTitle = mapDefaults.title;
			if ( markers[ markerIndex ].title ) {
				markerTitle = markers[ markerIndex ].title;
			}

			var markerImage = new google.maps.MarkerImage( mapDefaults.marker, new google.maps.Size( 29, 42 ), new google.maps.Point( 0, 0 ), new google.maps.Point( 15, 42 ) );
			if ( markers[ markerIndex ].markerImage ) {
				var markerScale = markers[ markerIndex ].markerScale || 1;
				var markerWidth = parseInt( markers[ markerIndex ].markerSize.width );
				var markerHeight = parseInt( markers[ markerIndex ].markerSize.height );
				var markerScaledWidth = markerWidth * markerScale;
				var markerScaledHeight = markerHeight * markerScale;

				markerImage = {
					url: markers[ markerIndex ].markerImage,
					scaledSize: new google.maps.Size( markerScaledWidth, markerScaledHeight ),
				};
				if ( markers[ markerIndex ].markerAnchor ) {
					markerImage.anchor = new google.maps.Point( parseInt( markers[ markerIndex ].markerAnchor.x ) * markerScale, parseInt( markers[ markerIndex ].markerAnchor.y ) * markerScale );
				}
			}

			var infowWindowContent;
			if ( mapSettings.infoWindows ) {
				var infowWindowContent = '<div class="info-window">';

				if ( markers[ markerIndex ].permalink ) {
					infowWindowContent += '<a href="' + markers[ markerIndex ].permalink + '" class="grid grid-cols-1 lg:grid-cols-2 invisible-link relative">';
				}

				if ( markers[ markerIndex ].image ) {
					infowWindowContent += '<div class="bg-cover bg-center bg-no-repeat aspect-video lg:aspect-auto" style="background-image: url(\'' + markers[ markerIndex ].image + '\');"></div><div class="p-3 lg:p-5 pb-5 lg:pb-10">';
				} else {
					infowWindowContent += '<div class="p-3 lg:p-5 pb-5 lg:pb-10 col-span-2">';
				}

				if ( markers[ markerIndex ].tags ) {
					infowWindowContent += '<div class="flex flex-wrap gap-2 pb-5">' + markers[ markerIndex ].tags + '</div>';
				}

				if ( markers[ markerIndex ].title ) {
					infowWindowContent += '<div class="h3 pb-2">' + markers[ markerIndex ].title + '</div>';
				}

				if ( markers[ markerIndex ].subtitle ) {
					infowWindowContent += '<div class="paragraph-3 line-clamp-3">' + markers[ markerIndex ].subtitle + '</div>';
				}

				infowWindowContent += '<div class="pt-5 lg:pt-10"><div class="button button-space relative hover:!text-space"><span class="block relative">' + markers[ markerIndex ].readMoreLabel + '</span></div></div>';

				infowWindowContent += '</div>';

				if ( markers[ markerIndex ].permalink ) {
					infowWindowContent += '</a>';
				}

				infowWindowContent += '</div>';
			}

			var label = null;
			if ( mapSettings.clusterer ) {
				label = {
					text: (mapSettings.clusterer) ? '1' : '',
					fontSize: '16px',
					color: 'white',
				};
			}

			var marker = new google.maps.Marker( {
				title: markerTitle,
				position: markerPosition,
				zIndex: 1,
				html: infowWindowContent,
				map: map,
				id: markers[ markerIndex ].id,
				icon: markerImage,
				label: label,
			} );

			infoWindow.setOptions( {
				maxWidth: 600,
			} );

			if ( infowWindowContent ) {
				google.maps.event.addListener( marker, 'click', function() {
					infoWindow.setContent( this.html );
					infoWindow.open( map, this );
				} );
			}

			if ( mapSettings.infoOverlay ) {
				google.maps.event.addListener( marker, 'click', function() {
					resetMapOverlays();
					jQuery( '#overlay-' + this.id ).removeClass( 'hidden' );
					map.setCenter( this.position );
					manualPositioning = true;

					googleMarkers.forEach( function( m ) {
						var label = m.getLabel();

						label.color = 'white';
						m.setLabel( label );

						if ( mapSettings.defaultMarkerImage ) {
							var defaultMarkerImage = {
								url: mapSettings.defaultMarkerImage,
								scaledSize: new google.maps.Size( markerScaledWidth, markerScaledHeight ),
							};
							m.setIcon( defaultMarkerImage );
						}
					} );

					var label = this.label;
					this.label.color = '#00261d';
					this.setLabel( label );

					if ( mapSettings.activeMarkerImage ) {
						var activeMarkerImage = {
							url: mapSettings.activeMarkerImage,
							scaledSize: new google.maps.Size( markerScaledWidth, markerScaledHeight ),
						};
						this.setIcon( activeMarkerImage );
					}
				} );
			}

			googleMarkers.push( marker );
		}
	}

	if ( mapSettings.clusterer ) {
		new markerClusterer.MarkerClusterer( {
			map: map,
			markers: googleMarkers,
			renderer: {
				render: function( o ) {
					var markers = o.markers;
					var position = o._position;
					return new google.maps.Marker( {
						title: markerTitle,
						position: {
							lat: position.lat(),
							lng: position.lng(),
						},
						zIndex: 1,
						map: map,
						icon: markerImage,
						label: {
							text: (markers.length > 10) ? '10+' : '' + markers.length,
							fontSize: '16px',
							color: 'white', // fontFamily, fontWeight
						},
					} );
				},
			},
		} );
	}

	if ( markers.length > 1 ) {
		map.fitBounds( latLonBounds );
	} else if ( markers.length ) {
		map.setCenter( new google.maps.LatLng( markers[ 0 ].latitude, markers[ 0 ].longitude ) );
	}

	// Center the map when resizing the window
	google.maps.event.addDomListener( window, 'resize', function() {
		map.setCenter( mapLocation );
		if ( markers.length > 1 ) {
			map.fitBounds( latLonBounds );
		} else if ( markers.length ) {
			map.setCenter( new google.maps.LatLng( markers[ 0 ].latitude, markers[ 0 ].longitude ) );
		}
	} );

	jQuery( '.map' ).on( 'click', function() {
		if ( !manualPositioning ) {
			resetMapOverlays();
		}

		manualPositioning = false;
	} );
}

function resetMapOverlays() {
	jQuery( '.map-overlay' ).addClass( 'hidden' );
}

function showStreetview( element, streetviewSettings ) {
	var povPitch = 50;
	if ( streetviewSettings.pitch ) {
		povPitch = streetviewSettings.pitch;
	}

	var povHeading = 120;
	if ( streetviewSettings.heading ) {
		povHeading = streetviewSettings.heading;
	}

	var panoramaOptions = {
		position: new google.maps.LatLng( streetviewSettings.latitude, streetviewSettings.longitude ),
		draggable: false,
		scrollwheel: false,
		pov: {
			heading: povHeading,
			pitch: povPitch,
		},
	};
	var panorama = new google.maps.StreetViewPanorama( element[ 0 ], panoramaOptions );
}

export function updateGoogleMapMarkers( ids ) {
	for ( var markerIndex = 0; markerIndex < googleMarkers.length; markerIndex++ ) {
		if ( ids === 'all' || ids.indexOf( googleMarkers[ markerIndex ].id ) > -1 ) {
			googleMarkers[ markerIndex ].setVisible( true );
		} else {
			googleMarkers[ markerIndex ].setVisible( false );
		}
	}

	fitGoogleMapMarkers();
}

export function fitGoogleMapMarkers() {
	latLonBounds = new google.maps.LatLngBounds();
	var visibleMarkerCounter = 0;

	for ( var markerIndex = 0; markerIndex < googleMarkers.length; markerIndex++ ) {
		if ( googleMarkers[ markerIndex ].visible ) {
			visibleMarkerCounter++;
			latLonBounds.extend( googleMarkers[ markerIndex ].position );
		}
	}

	if ( visibleMarkerCounter >= 1 ) {
		map.fitBounds( latLonBounds );
	}
}

window.createGoogleMap = createGoogleMap;
