import {animateInViewport} from "./transition";

$( function() {
	$( '.load-more' ).click( function( event ) {
		event.preventDefault();
		loadMore( $( this ) );
	} );

	var $loadMoreTo = $( '*[data-id="' + window.location.hash.substr( 1 ) + '"]' );
	if ( $loadMoreTo.length ) {
		while ( $loadMoreTo.hasClass( 'hidden' ) && !$( '.load-more' ).hasClass( 'hidden' ) ) {
			$( '.load-more' ).click();
		}
		$loadMoreTo.get( 0 ).scrollIntoView();
		setTimeout( function() {
			$( 'html' )
				.removeClass( 'scrolled-up' )
				.addClass( 'scrolled-down' );
		}, 500 );
	}

	$( '.load-more-item' ).click( function( event ) {
		var dataId = $( this ).attr( 'data-id' );
		if ( dataId ) {
			history.replaceState( undefined, undefined, '#' + dataId );
		}
	} );
} );

export function loadMore( $loadMore ) {
	var $container = $( $loadMore.attr( 'data-load-more-container' ) );
	$container.children( '.load-more-hidden:not(.hidden)' ).slice( 0, $loadMore.attr( 'data-load-more-items-per-page' ) )
		.each( function() {
			var $loadMoreItem = $( this );
			$loadMoreItem.removeClass( 'load-more-hidden' );
		} );

	setTimeout( () => {
		msnry.masonry();
	}, 0 );

	$loadMore.toggleClass( 'opacity-0 pointer-events-none', $container.children( '.load-more-hidden:not(.hidden)' ).length === 0 )
	animateInViewport();
}
